import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App />)



// import App from './App';

// ReactDOM.render(<App />, document.getElementById('root'));
