import React, { useState } from 'react';
import 'bootstrap';
import BGGviszlaavatar from './BGG-viszla.jpg';

import './App.css';

function App() {
  const shuffle = require('lodash.shuffle');
  const [roundNumber, setRoundNumber] = useState(0);
  const [topBarText, setTopBarText] = useState(
    'The Ancient World (2nd Ed) - Solo'
  );
  const [
    shuffledAutomatonActionCards,
    setShuffledAutomatonActionCards
  ] = useState([{}]);
  const [currentPhase, setCurrentPhase] = useState('start');
  const [difficultyLevel, setDifficultyLevel] = useState('training');
  const [firstPlayer, setFirstPlayer] = useState('Me');
  const [currentPlayer, setCurrentPlayer] = useState('Me');
  const [humanState, setHumanState] = useState('active');
  const [
    automatonCitizenTokensRemaining,
    setAutomatonCitizenTokensRemaining
  ] = useState(3);
  const [automatonActionCardIndex, setAutomatonActionCardIndex] = useState(1);

  const automatonActionCards = [
    {
      cardNumber: 1,
      action: 'place citizen',
      placeCitizenActionTarget: (
        <>
          <br />
          <br />
          <strong>Recruit</strong> and take the next available Military card in
          this order: position 2 / 1
          <br />
          <br />
          <span style={{ color: '#9da79c',fontStyle: 'italic' }}>- if above not available -</span>
          <br />
          <br />
          <strong>Labor</strong>
        </>
      ),
      attackTitanActionTarget: (
        <>
          <br />
          <br />
          Attack a Titan in the <strong>three-star</strong> pile, then turn the
          next card in that deck face up.
          <br />
          <br />
          <span style={{ color: '#9da79c',fontStyle: 'italic' }}>- if above not available -</span>
          <br />
          <br />
          Attack a Titan in the <strong>one-star</strong> pile, then turn the
          next card in that deck face up.
        </>
      )
    },
    {
      cardNumber: 2,
      action: 'place citizen',
      placeCitizenActionTarget: (
        <>
          <br />
          <br />
          the action space with the most <strong>Ambrosia</strong>.
        </>
      ),
      attackTitanActionTarget: (
        <>
          <br />
          <br />
          Attack a Titan in the <strong>two-star</strong> pile, then turn the
          next card in that deck face up.
          <br />
          <br />
          <span style={{ color: '#9da79c',fontStyle: 'italic' }}>- if above not available -</span>
          <br />
          <br />
          Attack a Titan in the <strong>three-star</strong> pile, then turn the
          next card in that deck face up.
        </>
      )
    },
    {
      cardNumber: 3,
      action: 'place citizen',
      placeCitizenActionTarget: (
        <>
          <br />
          <br />
          <strong>Build</strong> and take the next available Empire card in this
          order: position 2 / 3 / 4 / 1 / C deck
        </>
      ),
      attackTitanActionTarget: (
        <>
          <br />
          <br />
          Attack <strong>its own</strong> Titan
          <br />
          <br />
          <span style={{ color: '#9da79c' }}>
            - if above not available -
          </span>{' '}
          <br />
          <br />
          Attack <strong>your</strong> Titan
          <br />
          <br />
          <span style={{ color: '#9da79c' }}>
            - if above not available -
          </span>{' '}
          <br />
          <br />
          Attack a Titan in the <strong>two-star</strong> pile, then turn the
          next card in that deck face up.
        </>
      )
    },
    {
      cardNumber: 4,
      action: 'place citizen',
      placeCitizenActionTarget: (
        <>
          <br />
          <br />
          <strong>Build</strong> and take the next available Empire card in this
          order: position 1 / 2 / 3 / 4 / C deck
        </>
      ),
      attackTitanActionTarget: (
        <>
          <br />
          <br />
          Attack a Titan in the <strong>one-star</strong> pile, then turn the
          next card in that deck face up.
          <br />
          <br />
          <span style={{ color: '#9da79c',fontStyle: 'italic' }}>- if above not available -</span>
          <br />
          <br />
          Attack <strong>your</strong> Titan
          <br />
          <br />
          <span style={{ color: '#9da79c',fontStyle: 'italic' }}>- if above not available -</span>
          <br />
          <br />
          Attack a Titan in the <strong>two-star</strong> pile, then turn the
          next card in that deck face up.
        </>
      )
    },
    {
      cardNumber: 5,
      action: 'place citizen',
      placeCitizenActionTarget: (
        <>
          <br />
          <br />
          <strong>Build</strong> and take the next available Empire card in this
          order: position 4 / 1 / 2 / 3 / C deck
        </>
      ),
      attackTitanActionTarget: (
        <>
          <br />
          <br />
          Attack a Titan in the <strong>one-star</strong> pile, then turn the
          next card in that deck face up.
          <br />
          <br />
          <span style={{ color: '#9da79c',fontStyle: 'italic' }}>- if above not available -</span>
          <br />
          <br />
          Attack a Titan in the <strong>two-star</strong> pile, then turn the
          next card in that deck face up.
        </>
      )
    },
    {
      cardNumber: 6,
      action: 'place citizen',
      placeCitizenActionTarget: (
        <>
          <br />
          <br />
          <strong>Build</strong> and take the next available Empire card in this
          order: position 3 / 4 / 1 / 2 / C deck
        </>
      ),
      attackTitanActionTarget: (
        <>
          <br />
          <br />
          Attack <strong>its own</strong> Titan
          <br />
          <br />
          <span style={{ color: '#9da79c',fontStyle: 'italic' }}>- if above not available -</span>
          <br />
          <br />
          Attack a Titan in the <strong>one-star</strong> pile, then turn the
          next card in that deck face up.
          <br />
          <br />
          <span style={{ color: '#9da79c',fontStyle: 'italic' }}>- if above not available -</span>
          <br />
          <br />
          Attack a Titan in the <strong>two-star</strong> pile, then turn the
          next card in that deck face up.
        </>
      )
    },
    {
      cardNumber: 7,
      action: 'place citizen',
      placeCitizenActionTarget: (
        <>
          <br />
          <br />
          <strong>Build</strong> and take a random Empire card from the C deck
        </>
      ),
      attackTitanActionTarget: (
        <>
          <br />
          <br />
          Attack <strong>its own</strong> Titan
          <br />
          <br />
          <span style={{ color: '#9da79c',fontStyle: 'italic' }}>- if above not available -</span>
          <br />
          <br />
          Attack <strong>your</strong> Titan
          <br />
          <br />
          <span style={{ color: '#9da79c',fontStyle: 'italic' }}>- if above not available -</span>
          <br />
          <br />
          Attack a Titan in the <strong>two-star</strong> pile, then turn the
          next card in that deck face up.
        </>
      )
    },
    {
      cardNumber: 8,
      action: 'attack titan',
      placeCitizenActionTarget: (
        <>
          <br />
          <br />
          the action space with the most <strong>Ambrosia</strong>.
        </>
      ),
      attackTitanActionTarget: (
        <>
          <br />
          <br />
          Attack a Titan in the <strong>two-star</strong> pile, then turn the
          next card in that deck face up.
          <br />
          <br />
          <span style={{ color: '#9da79c',fontStyle: 'italic' }}>- if above not available -</span>
          <br />
          <br />
          Attack a Titan in the <strong>one-star</strong> pile, then turn the
          next card in that deck face up.
        </>
      )
    },
    {
      cardNumber: 9,
      action: 'attack titan',
      placeCitizenActionTarget: (
        <>
          <br />
          <br />
          the action space with the most <strong>Ambrosia</strong>.
        </>
      ),
      attackTitanActionTarget: (
        <>
          <br />
          <br />
          Attack <strong>your</strong> Titan
          <br />
          <br />
          <span style={{ color: '#9da79c',fontStyle: 'italic' }}>- if above not available -</span>
          <br />
          <br />
          Attack a Titan in the <strong>two-star</strong> pile, then turn the
          next card in that deck face up.
        </>
      )
    },
    {
      cardNumber: 10,
      action: 'attack titan',
      placeCitizenActionTarget: (
        <>
          <br />
          <br />
          <strong>Recruit</strong> and take the next available Military card in
          this order: position 1 / 2
          <br />
          <br />
          <span style={{ color: '#9da79c',fontStyle: 'italic' }}>- if above not available -</span>
          <br />
          <br />
          <strong>Rebuild</strong> (and take the 1st Player Token if first
          Citizen on action)
        </>
      ),
      attackTitanActionTarget: (
        <>
          <br />
          <br />
          Attack a Titan in the <strong>one-star</strong> pile, then turn the
          next card in that deck face up.
          <br />
          <br />
          <span style={{ color: '#9da79c',fontStyle: 'italic' }}>- if above not available -</span>
          <br />
          <br />
          Attack a Titan in the <strong>two-star</strong> pile, then turn the
          next card in that deck face up.
        </>
      )
    }
  ];

  function createDeck() {
    const tempDeck = shuffle(automatonActionCards);
    // console.log('tempDeck: ', tempDeck);
    setShuffledAutomatonActionCards(tempDeck);
  }

  const introPage = (
    <>
      <p className='mt-4'>
        The Ancient World (Second Edition) is played over the course of six
        rounds. Each round has 3 phases: <strong>Prepare</strong>,{' '}
        <strong>Player Actions / Automaton Actions</strong> and{' '}
        <strong>End of Round</strong>.
      </p>
      <p>
        You will play against an Automaton who will use Citizen tokens to take
        actions dictated by this app. During the course of a round, the
        Automaton will take a number of actions equal to the number of Citizen
        tokens it has available. It will start with three like a human player
        and gain up to five before the game ends.
      </p>
      <p style={{ textAlign: 'center' }}>
        The Automaton logic used in this app was developed by the BGG user{' '}
        <a
          href='https://boardgamegeek.com/user/viszla'
          rel='noopener noreferrer'
          target='_blank'
        >
          viszla
        </a>
        :
        <br />
        <br />
        <a
          href='https://boardgamegeek.com/user/viszla'
          rel='noopener noreferrer'
          target='_blank'
        >
          <img src={BGGviszlaavatar} alt='BGG-viszla-avatar' width='80' />
        </a>
      </p>
      <p>
        <strong>Please note: </strong> This is not an official Red Raven Games
        product and has no affiliation with Red Raven Games. The Ancient World
        was designed and illustrated by Ryan Laukat. This app was developed as a
        personal project and simply intended to assist with solo gameplay of The
        Ancient World (Second Edition) using the automata cards developed by{' '}
        <a
          href='https://boardgamegeek.com/user/viszla'
          rel='noopener noreferrer'
          target='_blank'
        >
          viszla
        </a>
        .
      </p>
      <p>
        The Automaton follows a simple algorithm each turn and is subject to a
        different set of rules than a human player.
      </p>
      <ul>
        <li>
          It does not have money, nor does it collect money, knowledge,
          ambrosia, district or military cards.
        </li>
        <li>It does not have an income or Empire limit.</li>
        <li>It doesn’t have to feed its Citizens.</li>
        <li>It collects only cards with banners (Empire and Titan).</li>
        <li>
          It will remove ambrosia when placing a Citizen on an action space with
          ambrosia on it.
        </li>
        <li>It does not pay for actions--everything is always free.</li>
        <li>
          It always has enough military power to defeat a Titan and Titans do
          not attack the Automaton.
        </li>
        <li>
          It will always use the highest skill number Citizen available when
          taking an action.
        </li>
      </ul>
      <p>
        Set up the main board and your player board as normal for a 2 player
        game, using the side of the player board that does not have the
        city-state name or special abilities.{' '}
        <strong>
          Place 1 ambrosia token each on the Learn, Rebuild, and Expand actions
        </strong>{' '}
        and <strong>give yourself the 1st player token</strong>.
      </p>
      <p>
        For the Automaton, use the side of a player board that does not have the
        city-state name or special abilities. Place the starting Threatening
        Titan card and Citizen tokens with skill levels 1, 2 and 3 only on that
        player board to start.
      </p>
      <div className='d-flex align-items-center pt-4 pb-3'>
        <button
          type='button'
          className='btn btn-lg btn-taw ml-auto mr-auto'
          onClick={handleStartButtonClick}
        >
          Start the Game!
        </button>
      </div>
    </>
  );

  function handleStartButtonClick() {
    setCurrentPhase('choose level');
    setTopBarText('Choose Difficulty Level');
  }

  const chooseLevelPage = (
    <>
      <h5 className='mt-4 mb-4'>Select a difficulty level to play:</h5>
      <div className='d-flex align-items-left pt-4 pb-3'>
        <button
          type='button'
          className='btn btn-lg btn-taw mr-4'
          onClick={() => handleLevelButtonClick('Training')}
        >
          Training
        </button>
        <p>Citizen with skill level 4 added in round 4</p>
      </div>
      <div className='d-flex align-items-left pt-4 pb-3'>
        <button
          type='button'
          className='btn btn-lg btn-taw mr-4'
          onClick={() => handleLevelButtonClick('Gentle')}
        >
          Gentle
        </button>
        <p>
          Citizen with skill level 4 added in round 4<br />
          Citizen with skill level 5 added in round 5
        </p>
      </div>
      <div className='d-flex align-items-left pt-4 pb-3'>
        <button
          type='button'
          className='btn btn-lg btn-taw mr-4'
          onClick={() => handleLevelButtonClick('Tough')}
        >
          Tough
        </button>
        <p>
          Citizen with skill level 4 added in round 3<br />
          Citizen with skill level 5 added in round 5
        </p>
      </div>
      <div className='d-flex align-items-left pt-4 pb-3'>
        <button
          type='button'
          className='btn btn-lg btn-taw mr-4'
          onClick={() => handleLevelButtonClick('Brutal')}
        >
          Brutal
        </button>
        <p>
          Citizen with skill level 4 added in round 3<br />
          Citizen with skill level 5 added in round 4
        </p>
      </div>
    </>
  );

  function handleLevelButtonClick(level) {
    setDifficultyLevel(level);
    createDeck();
    setRoundNumber(1);
    setCurrentPhase('prepare');
    setTopBarText('Prepare Phase');
  }

  function preparePage() {
    let newEffectText = (
      <p>
        Use the <strong>Empire A</strong> deck this round.
      </p>
    );

    if (roundNumber === 2) {
      newEffectText = (
        <p>
          <strong>
            NEW THIS ROUND: Reveal the Explore action and place one ambrosia on
            the action space
          </strong>
          .
        </p>
      );
    }
    if (roundNumber === 3) {
      if (difficultyLevel === 'Tough' || difficultyLevel === 'Brutal') {
        newEffectText = (
          <p>
            <strong>
              NEW THIS ROUND: Reveal the Grow action and place one ambrosia on
              the action space. ALSO, add the Citizen with skill level 4 to the
              Automaton player board.
            </strong>{' '}
            The Automaton will have four turns from this round on.
          </p>
        );
      } else {
        newEffectText = (
          <p>
            <strong>
              NEW THIS ROUND: Reveal the Grow action and place one ambrosia on
              the action space.
            </strong>
          </p>
        );
      }
    }
    if (roundNumber === 4) {
      if (difficultyLevel === 'Training' || difficultyLevel === 'Gentle') {
        newEffectText = (
          <p>
            <strong>NEW THIS ROUND:</strong> Switch to the{' '}
            <strong>Empire B</strong> deck from this round.{' '}
            <strong>
              ALSO, add the Citizen with skill level 4 to the Automaton player
              board.
            </strong>{' '}
            The Automaton will have four turns from this round on.
          </p>
        );
      }
      if (difficultyLevel === 'Tough') {
        newEffectText = (
          <p>
            <strong>NEW THIS ROUND:</strong> Switch to the{' '}
            <strong>Empire B</strong> deck from this round.
          </p>
        );
      }

      if (difficultyLevel === 'Brutal') {
        newEffectText = (
          <p>
            <strong>NEW THIS ROUND:</strong> Switch to the{' '}
            <strong>Empire B</strong> deck from this round.{' '}
            <strong>
              ALSO, add the Citizen with skill level 5 to the Automaton player
              board.
            </strong>{' '}
            The Automaton will have five turns from this round on.
          </p>
        );
      }
    }
    if (roundNumber === 5) {
      if (difficultyLevel === 'Gentle' || difficultyLevel === 'Tough') {
        newEffectText = (
          <p>
            <strong>
              NEW THIS ROUND: Add the Citizen with skill level 5 to the
              Automaton player board
            </strong>
            . The Automaton will have five turns from this round.
          </p>
        );
      } else {
        newEffectText = <p></p>;
      }
    }
    if (roundNumber === 6) {
      newEffectText = (
        <p>
          <strong>This is the Final Round!</strong>
        </p>
      );
    }

    return (
      <>
        <h5 className='mt-4 mb-4'>
          Advance the round marker to Round <strong>{roundNumber}</strong>.
        </h5>
        {newEffectText}
        {roundNumber > 1 && (
          <>
            <p>
              <strong>Refresh Card Decks:</strong> Remove any remaining Empire
              cards in the card row and place them in a discard pile to the side
              of the board. Draw 4 new cards from the current Empire deck and
              place them face up on the board in the Empire card track. If the
              Empire deck is ever depleted, shuffle the appropriate discard pile
              to refresh the draw deck.
            </p>
            <p>
              Remove any remaining military cards in the card row and place them
              at the bottom of the military deck. Draw 2 new cards from the
              military deck and place them face up in the military card track.
            </p>
          </>
        )}

        {roundNumber > 1 && (
          <>
            <p>
              <strong>Draw Titans:</strong> If you or the Automaton do not have
              a Titan card on your player boards, you must draw a new one and
              place it face up on each of the player boards. This is called a
              “threatening Titan” and represents a Titan directly threatening
              the players city-state. Draw yours first and then one for
              Automaton.
            </p>{' '}
            <p>
              This round, draw a Titan from the bottom of the{' '}
              <strong>
                {roundNumber === 2 && 'one-star'}
                {(roundNumber === 3 || roundNumber === 4) && 'two-star'}
                {(roundNumber === 5 || roundNumber === 6) && 'three-star'}
              </strong>{' '}
              Titan deck (not the face up Titan at the top of the deck). In the
              rare case that a Titan deck is depleted, draw from the
              next-highest available deck.
            </p>
            <p>
              <strong>
                If the Automaton did not defeat its Titan, feed the Titan by
                placing an appropriate number of ambrosia tokens from the supply
                onto that card.
              </strong>
            </p>
          </>
        )}

        <div className='d-flex align-items-center pt-4 pb-3'>
          <button
            type='button'
            className='btn btn-lg btn-taw ml-auto mr-auto'
            onClick={handleProceedToActionsButtonClick}
          >
            Proceed to {firstPlayer === 'Me' ? 'Player' : 'Automaton'} Actions
            Phase
          </button>
        </div>
      </>
    );
  }

  function handleProceedToActionsButtonClick() {
    let titleText = 'Player Actions Phase';
    if (firstPlayer === 'Automaton') {
      titleText = 'Automaton Actions Phase';
    }

    setTopBarText(titleText);
    setCurrentPhase('actions');
  }

  function playerActionsPage() {
    let automatonActionText = <p>Uh oh...</p>;

    let index = automatonActionCardIndex;
    // console.log('shuffledAutomatonActionCards: ', shuffledAutomatonActionCards);
    // console.log('index: ', index);

    if (currentPlayer === 'Automaton') {
      if (shuffledAutomatonActionCards[index].action === 'place citizen') {
        automatonActionText = (
          <h5 className='text-center mt-4 mb-4'>
            Place the Citizen token with skill level{' '}
            <strong>{automatonCitizenTokensRemaining}</strong> on...{' '}
            {shuffledAutomatonActionCards[index - 1].placeCitizenActionTarget}
          </h5>
        );
      } else {
        automatonActionText = (
          <h5 className='text-center mt-4 mb-4'>
            Use the Citizen token with skill level{' '}
            <strong>{automatonCitizenTokensRemaining}</strong> to...{' '}
            {shuffledAutomatonActionCards[index - 1].attackTitanActionTarget}
          </h5>
        );
      }
    }

    return (
      <>
        {currentPlayer === 'Me' && (
          <>
            <h5 className='mt-4 mb-4'>
              Your turn, take one of the available actions listed below OR Pass.
            </h5>
            <div className='accordion' id='playerActionAccordion'>
              <div className='card'>
                <div className='card-header' id='headingOne'>
                  <h2 className='mb-0'>
                    <button
                      className='btn btn-link collapsed'
                      type='button'
                      data-toggle='collapse'
                      data-target='#collapseOne'
                      aria-expanded='true'
                      aria-controls='collapseOne'
                    >
                      <strong>Place a Citizen</strong>
                    </button>
                  </h2>
                </div>

                <div
                  id='collapseOne'
                  className='collapse'
                  aria-labelledby='headingOne'
                  data-parent='#playerActionAccordion'
                >
                  <div className='card-body'>
                    <p>
                      You may place one of your Citizen tokens on an action
                      space on the board. If you place a Citizen on an action
                      space that has another Citizen with a higher skill number
                      (even one of yours), you must pay 1 coin to the supply.
                    </p>
                    <p>
                      As soon as you place your Citizen on an action space you
                      may collect any ambrosia tokens there. You may use these
                      and any other ambrosia you have in the current action.
                    </p>
                    <p>
                      You may place a Citizen on an action space, choose not to
                      perform the action, and still claim the ambrosia.
                    </p>
                    <p>
                      Alternatively, you may place a Citizen token on an Empire
                      card you own that has an action. Unlike actions on the
                      board, Empire card actions may only be used once per
                      round, and only by the player that owns the card unless
                      stated otherwise.
                    </p>
                  </div>
                </div>
              </div>
              <div className='card'>
                <div className='card-header' id='headingTwo'>
                  <h2 className='mb-0'>
                    <button
                      className='btn btn-link collapsed'
                      type='button'
                      data-toggle='collapse'
                      data-target='#collapseTwo'
                      aria-expanded='false'
                      aria-controls='collapseTwo'
                    >
                      <strong>Attack a Titan</strong>
                    </button>
                  </h2>
                </div>
                <div
                  id='collapseTwo'
                  className='collapse'
                  aria-labelledby='headingTwo'
                  data-parent='#playerActionAccordion'
                >
                  <div className='card-body'>
                    <p>
                      You may choose to attack one Titan: either your
                      Threatening Titan, one of the top cards on the one, two or
                      three-star piles, or the Threatening Titan on the
                      Automation player board.
                    </p>
                    <p>
                      To attack, you must pay coins to one or more military
                      cards that you own. You may pay coins to multiple military
                      cards in the same attack action if they have not already
                      been used in the round. Place your payment in coins on the
                      name area of the card. This indicates that the military
                      card has been used this round.
                    </p>
                    <p>
                      At the end of each round, any coins on the name area of
                      the card slide down to the picture area of the card. This
                      is important because the cost to use a military card is
                      equal to one more coin than is already on the card. Once
                      you pay a military card, you have temporary access to all
                      attack symbols and abilities on the card as well as legacy
                      abilities on retired military cards below it. This
                      temporary access lasts for one attack action, not the
                      entire round. To defeat the titan, the you must have an
                      attack value equal to or higher than the titan defense
                      value (listed on the titan card).
                    </p>
                    <p>
                      When you defeat a titan, take the card and put it to the
                      right of your player board in the row of districts and
                      titans. If you attacked a Titan in on of the one, two or
                      three-star decks, immediately turn the next card in the
                      titan deck face up. If you attacked a titan on a player
                      board, that space remains empty until a new titan is drawn
                      in the Prepare phase at the beginning of the next round.
                      If you attacked a titan on the Automaton’s board, you gain
                      any ambrosia on the card up to the number of tribe banners
                      on the titan.
                    </p>
                    <p>
                      As a last step, you must roll the damage dice, which
                      represent damage dealt by the Titan to your city-state.
                      You must roll one die per banner on the Titan card. The
                      effects are described below:
                    </p>
                    <ul>
                      <li>
                        <strong>Blank:</strong> No effect.
                      </li>
                      <li>
                        <strong>Coin Symbol:</strong> An immediate effect takes
                        place as detailed at the bottom of the defeated Titan
                        card.
                      </li>
                      <li>
                        <strong>Titan Symbol:</strong> 1 Empire card is damaged
                        per Titan symbol. You choose 1 Empire card you own to
                        flip face down. This card does not grant its abilities
                        until it is repaired. If you have no Empire cards that
                        can be damaged, nothing happens. If you damage an Empire
                        card that gives an extra army, you must immediately
                        discard any military cards in any armies that exceed the
                        limit (including retired mility cards).
                      </li>
                    </ul>
                    <p>
                      You may attack multiple Titans in a round (not in the same
                      turn), as long as you have at least one unused military
                      card. You may not attack without paying at least one
                      military card.
                    </p>
                  </div>
                </div>
              </div>
              <div className='card'>
                <div className='card-header' id='headingThree'>
                  <h2 className='mb-0'>
                    <button
                      className='btn btn-link collapsed'
                      type='button'
                      data-toggle='collapse'
                      data-target='#collapseThree'
                      aria-expanded='false'
                      aria-controls='collapseThree'
                    >
                      <strong>Build Stored</strong>
                    </button>
                  </h2>
                </div>
                <div
                  id='collapseThree'
                  className='collapse'
                  aria-labelledby='headingThree'
                  data-parent='#playerActionAccordion'
                >
                  <div className='card-body'>
                    You may build one Empire card that you have stored at the
                    bottom left corner of your player board. You do not need to
                    use a Citizen token to do this, but it does take an action.
                  </div>
                </div>
              </div>
            </div>
            <p className='mt-4'>
              <strong>REMEMBER:</strong> You may spend Ambrosia tokens to gain
              benefits at any time (with the exception of the 'Use a placed
              Citizen again' which must be used during a turn and counts as a
              normal action). There is no limit to the amount of Ambrosia you
              can spend at a time. The available actions are listed below and on
              your player board.
            </p>
            <div className='accordion' id='ambrosiaAccordion'>
              <div
                className='card'
                style={{
                  border: '1px solid rgba(0,0,0,.125)',
                  borderRadius: '.25rem'
                }}
              >
                <div className='card-header' id='ambrosiaHeading'>
                  <h2 className='mb-0'>
                    <button
                      className='btn btn-link collapsed'
                      type='button'
                      data-toggle='collapse'
                      data-target='#ambrosiaCollapse'
                      aria-expanded='true'
                      aria-controls='ambrosiaCollapse'
                    >
                      <strong>Ambrosia Actions</strong>
                    </button>
                  </h2>
                </div>
                <div
                  id='ambrosiaCollapse'
                  className='collapse'
                  aria-labelledby='ambrosiaHeading'
                  data-parent='#ambrosiaAccordion'
                >
                  <div className='card-body'>
                    <p>
                      <strong>
                        Use a placed citizen again (costs 4 ambrosia):
                      </strong>{' '}
                      The player may place any one of their citizens that have
                      already been placed on the board on a new action. They
                      take this new action immediately. All normal action and
                      placement rules apply.{' '}
                      <strong>
                        NOTE: This is not a free action. Players can only use
                        this ambrosia ability during their turn and it counts as
                        their normal action.
                      </strong>
                    </p>
                    <p>
                      <strong>Repair an empire card (costs 2 ambrosia):</strong>{' '}
                      The player may repair one of their damaged empire cards.
                      This can be done at any time.
                    </p>
                    <p>
                      <strong>Feed a titan (cost varies):</strong> The player
                      may pay ambrosia when confronting their threatening titan
                      at the end of the round in order to avoid rolling the
                      damage dice. The cost is one more ambrosia than is already
                      on the card, or ambrosia equal to the number of banners if
                      there is no ambrosia on the card. The player may not pay
                      part of the ambrosia cost to roll fewer dice. They must
                      pay the complete amount.
                    </p>
                    <p>
                      <strong>
                        Add 1 to citizen skill (costs 1 ambrosia):
                      </strong>{' '}
                      The player may pay ambrosia to temporarily increase a
                      citizen’s skill number (when placing on the board). The
                      skill increases only when placing the citizen and
                      immediately returns to normal after the citizen has been
                      placed and the action taken.
                    </p>
                    <p>
                      <strong>Feed a citizen (costs 2 ambrosia):</strong> The
                      player may pay 2 ambrosia to gain 1 temporary food when
                      checking for starvation. After this check, the food is
                      immediately lost.
                    </p>
                    <p>
                      <strong>Gain 1 sword (costs 3 ambrosia):</strong> The
                      player gains 1 temporary sword when attacking a titan.
                      This sword only lasts for one attack.
                    </p>
                    <p>
                      <strong>Gain 1 coin (costs 2 ambrosia):</strong> The
                      player immediately gains 1 coin.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <p className='mt-4'>
              If you cannot or do not wish to place a Citizen token, attack a
              Titan or build a stored Empire card, you must pass. Once you pass,
              you cannot take any more actions this round. The Automaton will
              take all of its actions each round, based on the number of Citizen
              tokens available to it.
            </p>
            <div className='d-flex align-items-center pt-4 pb-3'>
              {automatonCitizenTokensRemaining > 0 && (
                <button
                  type='button'
                  className='btn btn-lg btn-taw ml-auto mr-auto'
                  onClick={handleActionCompleteButtonClick}
                >
                  Action Complete
                </button>
              )}

              <button
                type='button'
                className='btn btn-lg btn-success ml-auto mr-auto'
                onClick={handlePassButtonClick}
              >
                Pass
              </button>
            </div>
          </>
        )}
        {currentPlayer === 'Automaton' && (
          <>
            {automatonActionText}
            <div className='d-flex align-items-center pt-4 pb-3'>
              <button
                type='button'
                className='btn btn-lg btn-taw ml-auto mr-auto'
                onClick={handleActionCompleteButtonClick}
              >
                Action Complete
              </button>
            </div>
          </>
        )}
      </>
    );
  }

  function handleActionCompleteButtonClick() {
    let nextPlayer = 'Automaton';
    let automatonTokens = automatonCitizenTokensRemaining;
    let cardIndex = JSON.parse(JSON.stringify(automatonActionCardIndex));
    let titleText = 'Player Actions Phase';

    if (currentPlayer === 'Me') {
      if (automatonCitizenTokensRemaining > 0) {
        nextPlayer = 'Automaton';
        titleText = 'Automaton Actions Phase';
      } else {
        nextPlayer = 'Me';
        titleText = 'Player Actions Phase';
      }
    }

    if (currentPlayer === 'Automaton') {
      if (automatonTokens > 0) {
        automatonTokens = automatonTokens - 1;
      }

      if (cardIndex === 9) {
        createDeck();
        cardIndex = 1;
      } else {
        cardIndex = cardIndex + 1;
      }

      if (humanState === 'active') {
        nextPlayer = 'Me';
        titleText = 'Player Actions Phase';
      } else {
        if (automatonTokens > 0) {
          nextPlayer = 'Automaton';
          titleText = 'Automaton Actions Phase';
        }
      }
    }

    setTopBarText(titleText);
    setCurrentPlayer(nextPlayer);
    setAutomatonCitizenTokensRemaining(automatonTokens);
    setAutomatonActionCardIndex(cardIndex);

    if (humanState === 'passed' && automatonTokens === 0) {
      if (roundNumber < 6) {
        setCurrentPhase('end of round');
        setTopBarText('End of Round Phase');
      } else {
        setCurrentPhase('game end');
        setTopBarText('Game End');
      }
    }
  }

  function handlePassButtonClick() {
    let titleText = 'Automaton Actions Phase';

    setHumanState('passed');

    if (automatonCitizenTokensRemaining === 0) {
      if (roundNumber < 6) {
        setCurrentPhase('end of round');
        setTopBarText('End of Round Phase');
      } else {
        setCurrentPhase('game end');
        setTopBarText('Game End');
      }
    } else {
      setCurrentPlayer('Automaton');
      setTopBarText(titleText);
    }
  }

  const endOfRoundPage = (
    <>
      <h5 className='mt-4 mb-4'>Perform the following End of Round steps:</h5>
      <ul>
        <li>
          <strong>Confront Threatening Titan:</strong> roll damage or feed. The
          ambrosia cost is equal to one more than is already on the card. If
          there is no ambrosia on the titan card, the cost is equal to the
          number of banners of the titan. You must pay the full amount of
          ambrosia required to avoid rolling the damage dice.
        </li>
        <li>
          <strong>Collect income:</strong> coins, knowledge, ambrosia.
        </li>
        <li>
          <strong>Slide down coins on Military cards</strong>
        </li>

        <li>
          <strong>Place New Ambrosia: </strong> place 1 ambrosia token on each
          (uncovered) action with no Citizen tokens.
        </li>
        <li>
          <strong>Reclaim Citizen tokens</strong>
        </li>
        <li>
          <strong>Flip 1st Player Token: </strong> set the token to the
          "non-hand" side.
        </li>
        <li>
          <strong>Feed Citizens:</strong> check to see if you can feed all of
          your citizens. If you do not have a food symbol for each citizen token
          that you own, you must place any unfed citizen tokens to the left of
          the player board, in the “starving/new” area. You may choose any of
          your citizens to place in the starving area. Citizen tokens in the
          “starving” area cannot be used in the next round.
        </li>
      </ul>
      <h6>
        1st Player Token Owner: <strong>{firstPlayer}</strong>
      </h6>
      <div className='d-flex align-items-left pt-2 pb-3'>
        <button
          type='button'
          className='btn btn-taw'
          onClick={handleChange1stPlayerButtonClick}
        >
          Change 1st Player Token Owner
        </button>
      </div>
      <div className='d-flex align-items-center pt-4 pb-3'>
        <button
          type='button'
          className='btn btn-lg btn-taw ml-auto mr-auto'
          onClick={handleNextRoundButtonClick}
        >
          Proceed to Next Round
        </button>
      </div>
    </>
  );

  function handleChange1stPlayerButtonClick() {
    let newFirstPlayer = firstPlayer;

    if (firstPlayer === 'Me') {
      newFirstPlayer = 'Automaton';
    } else {
      newFirstPlayer = 'Me';
    }
    setFirstPlayer(newFirstPlayer);
  }

  function handleNextRoundButtonClick() {
    let currentRoundNumber = roundNumber;
    currentRoundNumber = currentRoundNumber + 1;

    let tokensAvailable = 3;

    if (difficultyLevel === 'Training' && currentRoundNumber > 3) {
      tokensAvailable = 4;
    }

    if (difficultyLevel === 'Gentle') {
      if (currentRoundNumber === 4) {
        tokensAvailable = 4;
      }
      if (currentRoundNumber === 5 || currentRoundNumber === 6) {
        tokensAvailable = 5;
      }
    }

    if (difficultyLevel === 'Tough') {
      if (currentRoundNumber === 3 || currentRoundNumber === 4) {
        tokensAvailable = 4;
      }
      if (currentRoundNumber === 5 || currentRoundNumber === 6) {
        tokensAvailable = 5;
      }
    }

    if (difficultyLevel === 'Brutal') {
      if (currentRoundNumber === 3) {
        tokensAvailable = 4;
      }
      if (currentRoundNumber > 3) {
        tokensAvailable = 5;
      }
    }

    setRoundNumber(currentRoundNumber);
    setCurrentPhase('prepare');
    setTopBarText('Prepare Phase');
    setCurrentPlayer(firstPlayer);
    setHumanState('active');
    setAutomatonCitizenTokensRemaining(tokensAvailable);
  }

  const gameEndPage = (
    <>
      <h5 className='mt-4 mb-4'>
        Game Over&mdash;perform the following steps and tally up your victory
        points to determine the winner!
      </h5>
      <ul>
        <li>Confront your Threatening Titan (roll damage or feed).</li>
        <li>Check for starvation.</li>
      </ul>
      <p>
        First, you lose 2 victory points per starving Citizen and 1 victory
        point per damaged Empire card.
      </p>
      <p>
        Damaged Empire cards are now all repaired in preparation for scoring in
        the next two sections.
      </p>
      <p>
        Score victory points for each color of tribe banner you have on Empire
        and Titan cards up to a maximum of 6 banners per tribe. Any banners over
        6 in any color do not score victory points. See table on player board
        for victory points for each banner count.
      </p>
      <p>
        Finally, gain bonus victory points from all special Empire cards you
        have, as described by the scoring text on each card.
      </p>
      <p>
        To calculate the Automaton score, use the tribe banner scoring as
        detailed above for the Empire and Titan cards collected.
      </p>
      <p>Compare your scores to determine the winner!</p>
      <p>
        The difficulty Level was <strong>{difficultyLevel}</strong>
      </p>
      <div className='d-flex align-items-center pt-4 pb-3'>
        <button
          type='button'
          className='btn btn-lg btn-taw ml-auto mr-auto'
          onClick={handleResetButtonClick}
        >
          Play Again
        </button>
      </div>
    </>
  );

  function handleResetButtonClick() {
    setRoundNumber(0);
    setCurrentPhase('start');
    setCurrentPlayer('Me');
    setFirstPlayer('Me');
    setHumanState('active');
    setTopBarText('The Ancient World - Solo Mode');
    setAutomatonCitizenTokensRemaining(3);
    setAutomatonActionCardIndex(1);
    createDeck();
  }

  return (
    <>
      <nav className='sticky-top navbar navbar-expand navbar-dark bg-taw'>
        <div className='collapse navbar-collapse' id='navbarsExample02'>
          <ul className='navbar-nav ml-auto mr-auto'>
            <li>
              <div className='nav-link font-weight-bolder'>{topBarText}</div>
            </li>
          </ul>
        </div>
      </nav>

      <main role='main' className='container'>
        {currentPhase === 'start' && introPage}
        {currentPhase === 'choose level' && chooseLevelPage}
        {currentPhase === 'prepare' && preparePage()}
        {currentPhase === 'actions' && playerActionsPage()}
        {currentPhase === 'end of round' && endOfRoundPage}
        {currentPhase === 'game end' && gameEndPage}
      </main>
      <nav
        className='fixed-bottom navbar navbar-expand navbar-dark bg-taw'
        style={{ minHeight: '56px' }}
      >
        {roundNumber > 0 && (
          <>
            <div className='collapse navbar-collapse' id='navbarsExample02'>
              <ul className='navbar-nav mr-auto'>
                <li>
                  <div className='nav-link font-weight-bolder'>
                    Rnd: <strong>{roundNumber}</strong> of 6
                    <span
                      style={{
                        color: '#bbb',
                        marginLeft: '12px',
                        marginRight: '12px'
                      }}
                    >
                      |
                    </span>
                    1st Plr: <strong>{firstPlayer}</strong>
                  </div>
                </li>
              </ul>
            </div>
            <button
              type='button'
              className='btn btn-outline-light btn-sm'
              onClick={handleResetButtonClick}
            >
              Reset
            </button>
          </>
        )}
      </nav>
    </>
  );
}

export default App;
